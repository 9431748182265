<template>
  <body>
    <header class="main-header">
      <div class="topbar">
        <div class="container">
          <ul>
            <li class="text-size-info">
              <span>
                Tekstgrootte A <span class="large-text">A</span>
              </span>
            </li>
            <li class="search-box">
              <input type="text" title="Zoekterm" placeholder="Zoekterm">
              <a class="submit-arrow"></a>
            </li>
            <li class="deventer-link">
              <a href="https://www.deventer.nl" target="_blank">naar deventer.nl</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="container">
        <nav>
          <img src="@/assets/deventer-logo.svg" class="logo" alt="Deventer logo">
          <ul>
            <li>
              <a href="/werkzaamheden">
                Werkzaamheden
              </a>
            </li>
            <li class="active">
              <a href="/actueel">
                Actueel
              </a>
            </li>
            <li>
              <a href="/contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <section class="page-content" role="main">
      <div class="page-content-wrapper container">
        <article class="info-section">
          <div class="breadcrumb">
            <a href="/">Home</a> <span>></span> <a href="/actueel">Actueel</a> <span>></span> <a href="/weekendafsluiting">Weekendafsluiting N348 Eefde-Gorssel</a>
          </div>
          <div class="content">
            <img src="@/assets/readspeaker.png" width="174px" alt="">
            <h1>Weekendafsluiting N348 Eefde-Gorssel</h1>
            <span class="date">Laatst gewijzigd op 23 oktober om 15:46 uur</span>
            <div class="preamble">
              <p>
                Ook provincie Gelderland werkt aan de N348. Van vrijdag 30 oktober 20.00 uur tot maandag <br/> 2 november 06.00 uur is deze weg bij Eefde-Gorssel afgesloten voor verkeer. Dit is dicht bij Deventer, dus goed om rekening mee te houden.
              </p>
            </div>
            <div class="htmlField">
              <p>Doorgaand verkeer wordt omgeleid via de Lochemseweg (N339) en N826. Fietsers kunnen wel langs de werkzaamheden.</p>
              <p>Gelderland werkt aan de aan de kruising Quatre Brasweg – Flierderweg – Zutphenseweg (N348) in Eefde. De provincie verandert de inrichting van de kruising, zodat deze overzichtelijker en dus veiliger wordt. Kijk voor meer informatie op <a href="https://www.gelderland.nl">www.gelderland.nl</a>.</p>
            </div>
            <div class="image-bar">
              Klik op onderstaande beelden om ze te vergroten
              <ul>
                <li v-bind:style="{ 'background-image': 'url(' + require('@/assets/image1.png') + ')' }"></li>
                <li v-bind:style="{ 'background-image': 'url(' + require('@/assets/image2.png') + ')' }"></li>
                <li v-bind:style="{ 'background-image': 'url(' + require('@/assets/image3.png') + ')' }"></li>
              </ul>
            </div>
            <div class="feedback-bar">
              FEEDBACK
              <b>Hoe heeft deze informatie je geholpen?</b>
              <a href="">Ja</a>
              <a href="">Nee</a>
            </div>
          </div>
        </article>
        <article class="right-section">
          <img id="highlightImage" src="@/assets/n384.png" alt="">
          <a class="btn btn-primary btn-filled" href="/meer-informatie">Meer informatie</a>
          <a class="btn btn-primary" href="/google-maps">Naar Google Maps</a>
        </article>
      </div>
    </section>
    <footer class="main-footer">
      <div class="container">
        <ul class="footer-links">
          <li class="important-links">
            <h3>Belangrijke links</h3>
            <ul>
              <li>> Privacyverklaring</li>
              <li>> Cookieverklaring</li>
              <li>> Toegankelijkheidsverklaring</li>
            </ul>
          </li>
          <li class="social-links">
            <h3>Volg ons ook op</h3>
            <img src="@/assets/twitter.png" alt="">
            <img src="@/assets/facebook.png" alt="">
          </li>
          <li class="contact-info">
            <h3>Contact</h3>
            <ul>
              <li><img src="@/assets/tel-icon.svg" alt=""> <span>Bel naar <a>14 0570</a></span></li>
              <li><img src="@/assets/email-icon.svg" alt=""> <span><a href="">E-mail de gemeente</a></span></li>
            </ul>
          </li>
        </ul>
        <div class="footer-logo">
          <img src="@/assets/deventer-logo.svg" alt="">
        </div>
      </div>
    </footer>
  </body>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  mounted () {
    // const el = document.getElementById('highlightImage');
    // const sectionEl = document.querySelector('.right-section');
    // document.addEventListener("scroll", function() {
    //   // console.log(el.offsetTop)
    //   // console.log('test', window.scrollY)
    //   if(el.offsetTop < window.scrollY) {
    //     sectionEl.classList.add('sticky')
    //   } else {
    //     sectionEl.classList.remove('sticky')
    //   }
    // });
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;400;500;700;900&display=swap');

$deventer-default: #0065A2;
$theme-color: #43237A;

body {
  font-family: 'Roboto Slab', serif;
  padding: 0;
  margin: 0;
  font-size: 15px;
  background-color: $deventer-default;
  -webkit-font-smoothing: antialiased;
}

p {
  font-family: 'Roboto', sans-serif;
}

a {
  color: $deventer-default;
  text-decoration: none;
}

h1 {
  font-size: 32px;
  line-height: 43px;
  color: $theme-color;
  max-width: 470px;
}

#app {
  
}

.default-homepage {

}

.section-page {

}

.main-footer {
  font-family: 'Roboto', sans-serif;
  background-color: #EFEFEF;
  width: 100%;
  padding: 50px 0;
  .container {
    display: grid;
    grid-template-columns: 70% auto;
  }
  .footer-links {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 40px;
    .social-links {
      img {
        width: 43px;
        margin-right: 10px;
      }
    }
    .important-links {
      ul {
        list-style-type: none;
        padding: 0;
        li {
          line-height: 28px;
          font-weight: bold;
        }
      }
    }
    .contact-info {
      ul {
        list-style-type: none;
        padding: 0;
        li {
          display: grid;
          grid-template-columns: 35px auto;
          line-height: 28px;
          align-items: center;
          img {
            margin-right: 10px;
          }
          a {
            font-weight: bold;
          }
        }
      }
    }
    > li {
      h3 {
        margin-top: 0;
        margin-bottom: 30px;
        color: #0065A2;
        font-size: 18px;
        font-family: 'Roboto Slab', 'serif';
      }
    }
  }
  .footer-logo {
    text-align: right;
    img {
      width: 100%;
      max-width: 170px;
    }
  }
}
.btn {
  border: 1px solid $theme-color;
  padding: 14px 20px;
  display: block;
  margin: 10px 0;
  font-size: 16px;
  font-family: 'Roboto Slab', sans-serif;
  position: relative;
  &::after {
    content: '';
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid $theme-color;
    background-image: url('assets/arrow-purple.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
}

.btn-filled {
  background-color: $theme-color;
  color: white;
  &::after {
    border-left: 1px solid white;
    background-image: url('assets/arrow-white.svg');
  }
}

.btn-primary {
  color: $theme-color;
  &.btn-filled {
    color: white;
  }
}

.breadcrumb {
  color: #464646;
  font-size: 14px;
  a {
    color: #464646;
  }
  span {
    padding: 0 5px;
  }
}

.page-content {
  margin-top: 13px;
  .container {
    padding: 30px 70px 30px 45px;
  }
  .page-content-wrapper {
    display: grid;
    grid-template-columns: auto 306px;
    border-bottom: 8px solid $theme-color;
    background-color: white;
    .info-section {
      .content {
        padding: 40px 52px;
        max-width: 650px;
        line-height: 24px;
        h1 {
          margin: 42px 0 30px;
        }
        .feedback-bar {
          margin: 30px 0;
          font-family: 'Roboto', sans-serif;
          b {
            display: block;
          }
          a {
            display: inline-block;
            border: 1px solid $theme-color;
            color: $theme-color;
            padding: 10px 15px;
            margin-right: 20px;
            margin-top: 20px;
          }
        }
        .image-bar {
          color: #808080;
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 50px;
          ul {
            list-style-type: none;
            padding: 0;
            width: fit-content;
            display: grid;
            grid-auto-flow: column;
            grid-column-gap: 18px;
            margin: 13px 0;
            li {
              width: 114px;
              height: 114px;
              background-size: cover;
              background-position: center;
            }
          }
        }
        .date {
          font-size: 14px;
          color: #808080;
        }
        .preamble {
          font-size: 15px;
          font-weight: bold;
        }
        .htmlField {
          margin-bottom: 40px;
          a {
            font-weight: bold;
          }
          p {
            margin: 25px 0;
          }
        }
      }
    }
    .right-section {
      margin-top: -30px;
      &.sticky {
        position: fixed;
        top: 0;
      }
      img {
        margin-bottom: 40px;
        min-width: calc(100% + 70px);
        width: calc(-37% + ((100vw - 100%) / 2) + 45px)
      }
    }
  }
}

.topbar {
  width: 100%;
  background-color: white;
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 10px;
    width: fit-content;
    padding: 0;
    margin: 0;
    float: right;
    li {
      font-size: 16px;
      &.text-size-info {
        color: #000000;
        margin-right: 30px;
        span {
          border-bottom: 1px solid $deventer-default;
          .large-text {
            font-size: 22px;
            border: none;
          }
        }
      }
      &.search-box {
        margin-right: 45px;
        input {
          padding: 12px;
          box-sizing: border-box;
          border: 1px solid $deventer-default;
          font-size: 16px;
          height: 44px;
          font-family: 'Roboto', sans-serif;
          &:focus {
            outline: none;
          }
          &::placeholder {
            color: #000000;
            opacity: 1;
          }

          &:-ms-input-placeholder {
            color: #000000;
          }

          &::-ms-input-placeholder {
            color: #000000;
          }
        }
        .submit-arrow {
          width: 44px;
          height: 44px;
          border: 1px solid #0065A2;
          background-color: white;
          border-left: none;
          background-image: url('assets/arrow.svg');
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          top: 13.5px;
          box-sizing: border-box;
        }
      }
      &.deventer-link {
        background-color: $deventer-default;
        a {
          color: white;
          padding: 0 42px;
          display: block;
          height: 100%;
        }
      }
    }
  }
}

.container {
  width: 100%;
  max-width: 1194px;
  box-sizing: border-box;
  margin: 0 auto;
}

.main-header {

  nav {
    display: grid;
    grid-template-columns: 267px auto;
    background-color: white;
    width: calc(100% + ((100vw - 100%) / 2));
    padding: 20px 30px 45px 30px;
    box-sizing: border-box;
    .logo {
      width: 195px;
    }
    ul {
      color: $deventer-default;
      font-size: 20px;
      font-weight: normal;
      list-style-type: none;
      padding: 0 20px;
      margin: 0;
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 30px;
      align-items: center;
      width: fit-content;
      li {
        &.active {
          a {
            font-weight: bold;
            border-bottom: 2px solid $theme-color;
          }
        }
        a {
          padding: 5px 0;
        }
      }
    }
  }
}
</style>
